<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Настройка: Подвал</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Разделы подвала</h5>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <a class="btn btn-primary" @click="$refs.editModal.open(0, 'create')">Добавить</a>
        </div>
      </div>
      <div class="card-body">
        <footer-table :list="list" @removeItem="removeItem" @getList="getList" />
      </div>
    </div>
    <edit-footer-block-modal ref="editModal" @getList="getList" />
  </div>
</template>

<script>
import filters from '@/entities/filters'
import FooterTable from '@/components/footer/FooterTable'
import EditFooterBlockModal from '@/components/footer/EditFooterBlockModal'

export default {
  name: 'FooterSettings',

  components: {
    FooterTable,
    EditFooterBlockModal,
  },

  data() {
    return {
      list: [],
      search: '',
      filters,
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      try {
        const data = (await this.$services.get('admin/footers')).data.data
        this.list = [...data.footers.data, {
          id: 0,
          name: data.socials.ru.name,
        }]
      } catch (e) {
        console.log(e)
      }
    },
    async removeItem(id) {
      try {
        await this.$services.delete(`admin/footers/${id}`)
        await this.getList()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
