import { render, staticRenderFns } from "./FooterTable.vue?vue&type=template&id=521d92d2&scoped=true&"
import script from "./FooterTable.vue?vue&type=script&lang=js&"
export * from "./FooterTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521d92d2",
  null
  
)

export default component.exports