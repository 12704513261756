<template>
  <a-modal v-model="visible" :title="form.ru.name" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Название(ru)" required>
        <a-input  v-model="form.ru.name" />
      </a-form-model-item>
      <a-form-model-item label="Название(ua)" required>
        <a-input  v-model="form.ua.name" />
      </a-form-model-item>
      <div v-for="value in form.social_values" class="value_list" :key="value.id">
        <a-form-model-item label="Иконка" required>
          <div class="icon" style="background-color: #f2f7fa">
            <img :src="value.icon" />
          </div>
        </a-form-model-item>
        <a-form-model-item label="Ссылка" required>
          <a-input  v-model="value.alias" />
        </a-form-model-item>
        <a-form-model-item label="Статус" required>
          <a-select v-model="value.active" placeholder="please select your zone">
            <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Позиция" required>
          <a-input  v-model="value.position" />
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import users from '@/mixins/users'
import statuses from '@/entities/statuses'

export default {
  name: 'EditFooterSocialBlock',
  mixins: [users],
  data() {
    return {
      statuses,
      form: {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
        social_values: [],
      },
      visible: false,
    }
  },
  computed: {
    isDisabled() {
      const form = this.form
      let isDisabled = false
      if (!form.ua.name || !form.ru.name) isDisabled = true

      for (const index in form.social_values) {
        if (typeof form.social_values[index] !== 'object' && !typeof form.social_values[index]) isDisabled = true

        for (const item in form.social_values[index]) {
          if (typeof form.social_values[index][item] !== 'object' && !`${form.social_values[index][item]}`) isDisabled = true

          if (typeof form.social_values[index][item] === 'object') {
            for (const prop in form.social_values[index][item]) {
              if (!`${form.social_values[index][item][prop]}`) isDisabled = true
            }
          }
        }
      }

      return isDisabled
    },
  },
  methods: {
    async open() {
      try {
        const record = (await this.$services.get('admin/social')).data.data.values
        this.form = record
        this.visible = true
      } catch (e) {
        console.log(e)
      }
    },
    async submit() {
      try {
        await this.$services.put('admin/social', this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.$emit('getList')
      this.form = {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
        social_values: [],
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  .value_list {
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 24px;
  }
  .icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    img {
      width: auto;
      height: 100%;
      max-width: 30px;
      margin: 0 auto;
      display: block;
    }
  }
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
